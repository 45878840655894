import React from 'react'

import Layout from '../components/Layout'
import { FullPageHero } from '../components/Sections'

const NotFoundPage = ({ location }) => {
  const localeID = 'sk_SK'
  return (
    <Layout location={location} title="404" localeID={localeID}>
      <FullPageHero localeID={localeID} title="404">
        Stránku, ktorú hľadáte sme nenašli
      </FullPageHero>
    </Layout>
  )
}

export default NotFoundPage
